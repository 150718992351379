import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Some things that make me awesome...`}</h2>
    <ProjectCard title="Caring for other" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
  Theres a guy I know that says I'm the most caring person he knows. All I know is that I have a lot of time for those close to me and for those who need it.
    </ProjectCard>
    <ProjectCard title="Hair dryer donator" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
  I like to leave hair dryers everywhere, just in case someone needs to use it, or in case I ever go back.
    </ProjectCard>
    <ProjectCard title="Tea Drinker" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
  You cannot beat a good cup of tea in the morning. I try to get 3 in before I leave the house. I'll even take one to the shower to drink while my conditioner works it magic!
    </ProjectCard>
    <ProjectCard title="Cocktail Master" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
  I have had a grand total of 2 Singapore Slings. I'm reliably informed that one of them wasn't even a Singapore Sling, but they were both lovely.
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      