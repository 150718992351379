import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`A little about me...`}</h2>
    <blockquote>
      <p parentName="blockquote">{`Sitting here in this Irish bar I am reading this for the first time. As a result, I'd quite like to kick the guy sitting opposite me in the nuts right now. But he's a nice guy and means well. I even think he kinda likes me. As revenge I think I'll make him go snowboarding! That'll teach him!`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      